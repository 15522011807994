// odometer containers
import OdometerContainer from '../../containers/OdometerContainer/OdometerContainer';

// react
import React from 'react';

// react dom
import {withRouter} from 'react-router-dom';

const OdometerPage = ({
  match: {
    params: {token},
  },
}) => <OdometerContainer token={token} />;

export default withRouter(OdometerPage);
