import {combineReducers} from 'redux';

// reducers
import layout from '../layout/redux/reducer';

// local reducers
import page from '../layout/containers/Page/redux/reducer';

export default combineReducers({
  layout,

  page,
});
