import {NUMBER} from '@matthahn/sally-fw/lib/inputTypes';

// Attribute
import attribute from '@matthahn/sally-fw/lib/attribute/attribute';

// Types
// import commaSeperatedNumber from '@matthahn/sally-fw/lib/type/types/commaSeperatedNumber.type';
// import formattedNumber from '@matthahn/sally-fw/lib/type/types/formattedNumber.type';
import integer from '@matthahn/sally-fw/lib/type/types/integer.type';
import number from '@matthahn/sally-fw/lib/type/types/number.type';

export default attribute({
  type: NUMBER,
  attribute: 'odo_reading',
  display: integer,
  input: integer,
  api: number,
  label: {
    default: 'Odometer Reading',
    short: 'Odometer Reading',
  },
});
