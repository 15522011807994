import styled from 'styled-components';

const FileInput = styled.input`
  display: none;
  visibility: hidden;
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
  margin: 0;
  padding: 0;
  border: 0;
`;

export default FileInput;
