import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body {
    min-height: 100vh;
    background: #cb2d3e;
    background: -webkit-linear-gradient(to top, #ef473a, #cb2d3e);
    background: linear-gradient(to top, #ef473a, #cb2d3e);
  }
`;

export default GlobalStyle;
