// alertify
import alertify from '@matthahn/sally-ui/lib/libs/alert';

// components
import Button from '@matthahn/sally-ui/lib/components/Button/Button';

// error lib
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// layout components
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';
import Logo from '../../../layout/components/Logo/Logo';

// odometer api
import submitOdometerReadingApi from '../../api/submitReading.api.odometer';

// odometer attributes
import odoReadingAttribute from '../../attributes/odo_reading.attribute.odometer';

// odometer components
import Container from '../../components/Container/Container';
import FileInput from '../../components/FileInput/FileInput';
import LogoContainer from '../../components/LogoContainer/LogoContainer';
import Text from '../../components/Text/Text';

// propTypes
import PropTypes from 'prop-types';

// react
import React, {Component, createRef} from 'react';

class OdometerContainer extends Component {
  static propTypes = {
    token: PropTypes.string,
  };

  inputDom = createRef();

  state = {
    completed: false,
    loading: false,
    odo_reading: odoReadingAttribute(''),
  };

  change = (value, key) => {
    if (this.state.loading) return;
    this.setState({
      [key]: value,
    });
  };

  fileSelected = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    this.imageSelected(file);
  };

  imageSelected = (image) => {
    if (this.state.loading) return;
    this.uploadImage(image);
  };

  clickOnFileInput = () => {
    this.inputDom.current.click();
  };

  uploadImage = async (image) => {
    const {token} = this.props;
    const {loading, odo_reading: odoReadingAttributeObject} = this.state;

    const odo_reading = odoReadingAttributeObject.api.format();

    if (loading || !image) return;
    if (!odo_reading) return alertify.info('Please enter odometer reading');
    this.setState({loading: true});

    try {
      const formMeta = new FormData();

      formMeta.append('odo_img', image);
      formMeta.append('token', token);
      formMeta.append('odo_reading', odo_reading);
      await submitOdometerReadingApi(formMeta);
      this.setState({completed: true, loading: false});
    } catch (error) {
      const {message} = parseError(error);
      alertify.warning(message);
      this.setState({loading: false});
    }
  };

  render() {
    const {loading, odo_reading, completed} = this.state;
    const odoReading = odo_reading.api.format();
    return completed ? (
      <Container>
        <LogoContainer>
          <Logo color="white" />
        </LogoContainer>
        <i className="mdi mdi-check" />
        <Text>
          Thank you for submitting your odometer reading. You can now close the
          window.
        </Text>
      </Container>
    ) : (
      <Container>
        <LogoContainer>
          <Logo color="white" />
        </LogoContainer>
        <Text>
          We kindly request for you to confirm the current odometer on your
          Sally vehicle. This will help us ensure proper vehicle maintenance.
          Thank you for your cooperation.
        </Text>
        <AttributeInput
          value={odo_reading}
          onChange={this.change}
          size="large"
          withBackground
          preIcon="dashboard"
          noBorder
          moreRadius
          placeholder="Odometer Reading"
          disabled={loading}
          autoFocus
        />
        <Button
          size="large"
          theme="black"
          icon="camera"
          onClick={this.clickOnFileInput}
          disabled={!odoReading}
          loading={loading}
        >
          {loading ? 'Uploading ...' : 'Take a picture'}
        </Button>
        <FileInput
          ref={this.inputDom}
          type="file"
          value=""
          onChange={this.fileSelected}
          accept="image/*"
          capture="environment"
        />
      </Container>
    );
  }
}

export default OdometerContainer;
