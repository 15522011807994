// layout page
import NotFoundPage from '../../../layout/pages/NotFoundPage/NotFoundPage';

// odometer page
import OdometerPage from '../../../odometer/pages/OdometerPage/OdometerPage';
import routeOdometer from '../../../odometer/pages/OdometerPage/route';

// react
import React from 'react';

// react router
import {Route, Switch} from 'react-router-dom';
import {ScrollContext} from 'react-router-scroll-4';

const Routes = () => (
  <ScrollContext>
    <Switch>
      <Route path={routeOdometer()} exact>
        <OdometerPage />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  </ScrollContext>
);

export default Routes;
