import React from 'react';
import styled from 'styled-components';

// Components
import {Center} from '@matthahn/sally-ui';

// Helper Components
const Container = styled.div`
  width: 100%;
  height: 100vh;
  color: white !important;
`;

const H1 = styled.div`
  font-size: 24px;
`;

const H2 = styled.div`
  font-size: 18px;
`;

const Page404 = () => (
  <Container>
    <Center>
      <div>
        <H1>(╯°□°）╯︵ ┻━┻</H1>
        <br />
        <br />
        <H2>Move along.. Nothing to see here..</H2>
      </div>
    </Center>
  </Container>
);

export default Page404;
