import styled from 'styled-components';

const LogoContainer = styled.div`
  height: 30px;

  & > img {
    height: 100%;
    display: block;
  }
`;

export default LogoContainer;
