import React, {Component} from 'react';
import {BrowserRouter, Route} from 'react-router-dom';

// Components
import {
  Page,
  NotificationSystem,
  Portal,
  AlertSystem,
} from '@matthahn/sally-ui';
import Routes from '../../../routes/components/Routes/Routes';
import GlobalStyle from '../GlobalStyle/GlobalStyle';

class App extends Component {
  render() {
    return (
      <Page noMinHeight>
        <GlobalStyle />
        <BrowserRouter>
          <Route component={Routes} />
        </BrowserRouter>
        <Portal container={Portal.assetsContainer.secondary} always>
          <NotificationSystem />
          <AlertSystem />
        </Portal>
      </Page>
    );
  }
}

export default App;
