import styled from 'styled-components';

// colors
import {white} from '@matthahn/sally-ui/lib/libs/colors';

const Container = styled.div`
  width: 100%;
  max-width: 400px;
  min-height: 100vh;
  margin: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  color: ${white};

  & > i {
    font-size: 100px;
  }

  @media (max-width: 768px) {
    min-height: initial;
    padding-top: 100px;
  }
`;

export default Container;
