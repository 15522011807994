import f3tch from 'f3tch';

// Constants
import API_URL from '../../api/constants/url.const.api';

// Profiles
import profile from '@matthahn/sally-fw/lib/api/profiles/fileUpload.profile.api';

const submitOdometerReadingApi = (odometerReading) =>
  f3tch('/drivers/odo_submission/')
    .profile(
      profile({
        url: API_URL(),
        ignoreAppVersionHeader: true,
        authorize: false
      })
    )
    .body(odometerReading)
    .post();

export default submitOdometerReadingApi;
